import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAnalytics(ctx, params) {
      return useJwt.getOrderAnalytics(params).then(response => response);
    },
    fetchOrders(ctx, params) {
      return useJwt.getAdminOrders(params).then(response => response);
    },
    getOrderDetail(ctx, id) {
      return useJwt.getOrderDetail(id).then(response => response);
    },
    createOrder(ctx, data) {
      return useJwt.createOrder(data).then(response => response);
    },
    updateOrder(ctx, data) {
      return useJwt.updateOrder(data).then(response => response);
    },
    importOrders(ctx, data) {
      return useJwt.importOrders(data).then(response => response);
    },
    exportOrders(ctx, data) {
      return useJwt.exportOrders(data).then(response => response);
    },
    deleteOrders(ctx, data) {
      return useJwt.deleteOrders(data).then(response => response);
    },
  },
};
